import React from 'react'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'
import { withTranslation } from 'i18n'

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  divider: {
    margin: theme.spacing(4, 0),
  },
  content: {
    textAlign: 'center',
  },
  image: {
    height: 128,
  },
  typography: {
    margin: theme.spacing(2, 0),
  },
}))

const Empty = ({ t, title, message, divider = 'none' }) => {
  const classes = useStyles()

  return (
    <>
      {divider.match('both|top') && <Divider className={classes.divider} />}
      <Grid className={classes.flex}>
        <Grid className={classes.content}>
          <img
            src='/static/images/illustrations/no-data.svg'
            alt=''
            className={classes.image}
          />
          <Typography variant='subtitle1' className={classes.typography}>
            {title || t('info.empty')}
          </Typography>
          {message && (
            <Typography variant='caption' className={classes.typography}>
              {message}
            </Typography>
          )}
        </Grid>
      </Grid>
      {divider.match('both|bottom') && <Divider className={classes.divider} />}
    </>
  )
}

export default withTranslation('common')(Empty)
