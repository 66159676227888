import React from 'react';
import { ListItem, ListItemIcon, ListItemText, Checkbox } from '@material-ui/core';

function SearchItem(props) {
  let checked = false;

  if (props.value === '') {
    checked = props.filter.length === 0;
  } else {
    checked = props.filter.filter((e) => e === props.value).length > 0;
  }

  return (
    <ListItem>
      <ListItemIcon>
        <Checkbox
          color='primary'
          checked={checked}
          onClick={() => props.onFilter(props.value)}
        />
      </ListItemIcon>
      <ListItemText>{props.label}</ListItemText>
    </ListItem>
  );
}

export default SearchItem;
