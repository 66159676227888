import React, { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { compose } from 'recompose';
import { withTranslation } from 'i18n';
import { AppBar, Button, Grid, Toolbar, Typography } from '@material-ui/core';

// Actions
import { initAutomationPlan } from 'src/components/layout/messages/automationPlan/AutomationPlanAction';
import { openModalAutomationPlan } from 'src/components/modal/modalAutomationPlan/ModalAutomationPlanAction';

function AutomationPlan(props) {
  const dispatch = useDispatch();

  const automationPlan = useSelector((state) => state.automationPlanReducer.automationPlan);

  const [initializesPage, setInitializesPage] = useState(false);

  useEffect(() => {
    if (!initializesPage) {
      setInitializesPage(true);
      dispatch(initAutomationPlan());
    }
  }, [initializesPage]);

  if (!automationPlan.automationExceedingPlan) {
    return (<Fragment />)
  }

  const onPlan = () => {
    dispatch(openModalAutomationPlan(automationPlan))
  }

  return (
    <AppBar style={{ backgroundColor: '#FF8F00', minHeight: 40, maxHeight: 40  }}>
      <Toolbar style={{ padding: 0, justifyContent: 'space-between', alignItems: 'center', minHeight: 40, maxHeight: 40 }}>
        <Grid style={{ display: 'inline-flex', flex: 'auto', justifyContent: 'center', alignItems: 'center' }}>
          <Typography style={{ fontSize: 12, textAlign: 'center' }}>
            {props.t('Temos_uma_otima_noticia_para_voce__sua_base_de_contatos_esta_crescendo__Fiquei_tranquilo__que_iremos_atualizar_o_plano_automaticamente_e_suas_automacoes_nao_serao_afetadas')}
          </Typography>

          <Button size='small' style={{ marginLeft: 12, minWidth: 100 }} onClick={() => onPlan()}>
              {props.t('SABER_MAIS')}
          </Button>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

export default compose(
  withTranslation('translate')
)(AutomationPlan)

// ==================================================
// Estilos
// ==================================================
