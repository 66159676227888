import React, { forwardRef } from 'react'
import NextLink from 'next/link'
import { withRouter } from 'next/router'

const NextComposed = forwardRef(function NextComposed(props, ref) {
  const { as, href, prefetch, ...rest } = props

  return (
    <NextLink href={href} as={as} prefetch={prefetch}>
      <a ref={ref} {...rest} />
    </NextLink>
  )
})

const Link = withRouter(NextComposed)

export default forwardRef((props, ref) => <Link ref={ref} {...props} />)
