import cookies from 'js-cookie';

// Serviços
import Api from 'src/services/Api';

// ==================================================
// Seleciona aba do menu
// ==================================================
export function setPage(value) {
  return {
    type: 'SEARCH_MODULE_STEP_FETCHED',
    payload: value,
  };
}

// ==================================================
// Inicia listas
// ==================================================
export function initSearch(query, filter) {
  let page = 'contacts';

  if (filter.length > 0) {
    if (filter.filter((item) => item === 'contacts').length > 0) page = 'contacts';
    else if (filter.filter((item) => item === 'lists').length > 0) page = 'lists';
    else if (filter.filter((item) => item === 'campaigns').length > 0) page = 'campaigns';
    else if (filter.filter((item) => item === 'templates').length > 0) page = 'templates';
    else if (filter.filter((item) => item === 'landing_pages').length > 0) page = 'landing_pages';
  }

  return [
    setPage(page),
    cleanSearchQueryList(),
    setSearchQueryList(query),
    cleanSearchFilterList(),
    setSearchFilterList(filter),
    cleanSearchLeads(),
    cleanSearchLists(),
    cleanSearchCampaigns(),
    cleanSearchTemplates(),
    cleanSearchLandingPages(),
  ];
}

// ==================================================
// Busca lista de contatos
// ==================================================
function cleanSearchLeads() {
  return {
    type: 'CLEAN_SEARCH_LEADS_FETCHED',
    payload: '',
  };
}
function setSearchLeads(items) {
  return {
    type: 'SEARCH_LEADS_FETCHED',
    payload: items,
  };
}
function getSearchLead(query, _meta) {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().get(`/organizacoes/${organization}/localizar/leads?search=${query}&page=${_meta.current_page ? (_meta.current_page + 1) : 1}&per_page=${_meta.per_page || 50}`)
      .then((items) => {
        const _newMeta = {
          current_page: _meta.current_page || 0,
          per_page: _meta.per_page || 50,
          total_items: items.data.Meta.totalItems,
          total_pages: items.data.Meta.totalPage,
        };
        dispatch(setSearchLeads({ resultado: items.data.data, _meta: _newMeta }));
      })
      .catch((error) => {
        dispatch(setSearchLeads({ data: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },));
      })
      .finally(() => {
      });
  };
}
export function getSearchLeads(query, _meta) {
  return [
    cleanSearchLeads(),
    getSearchLead(query, _meta)
  ];
}

// ==================================================
// Busca lista de listas
// ==================================================
function cleanSearchLists() {
  return {
    type: 'CLEAN_SEARCH_LISTS_FETCHED',
    payload: '',
  };
}
function setSearchLists(items) {
  return {
    type: 'SEARCH_LISTS_FETCHED',
    payload: items,
  };
}
function getSearchList(query, _meta) {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().get(`/organizacoes/${organization}/localizar/lists?search=${query}&page=${_meta.current_page ? (_meta.current_page + 1) : 1}&per_page=${_meta.per_page || 50}`)
      .then((items) => {
        const _newMeta = {
          current_page: _meta.current_page || 0,
          per_page: _meta.per_page || 50,
          total_items: items.data.Meta.totalItems,
          total_pages: items.data.Meta.totalPage,
        };
        dispatch(setSearchLists({ resultado: items.data.data, _meta: _newMeta }));
      })
      .catch((error) => {
        dispatch(setSearchLists({ data: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },));
      })
      .finally(() => {
      });
  };
}
export function getSearchLists(query, _meta) {
  return [
    cleanSearchLists(),
    getSearchList(query, _meta)
  ];
}

// ==================================================
// Busca lista de campanhas
// ==================================================
function cleanSearchCampaigns() {
  return {
    type: 'CLEAN_CLEAN_SEARCH_CAMPAIGNS_FETCHED',
    payload: '',
  };
}
function setSearchCampaigns(items) {
  return {
    type: 'SEARCH_CAMPAIGNS_FETCHED',
    payload: items,
  };
}
function getSearchCampaign(query, _meta) {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().get(`/organizacoes/${organization}/localizar/campaigns?search=${query}&page=${_meta.current_page ? (_meta.current_page + 1) : 1}&per_page=${_meta.per_page || 50}`)
      .then((items) => {
        const _newMeta = {
          current_page: _meta.current_page || 0,
          per_page: _meta.per_page || 50,
          total_items: items.data.Meta.totalItems,
          total_pages: items.data.Meta.totalPage,
        };
        dispatch(setSearchCampaigns({ resultado: items.data.data, _meta: _newMeta }));
      })
      .catch((error) => {
        dispatch(setSearchCampaigns({ data: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },));
      })
      .finally(() => {
      });
  };
}
export function getSearchCampaigns(query, _meta) {
  return [
    cleanSearchCampaigns(),
    getSearchCampaign(query, _meta)
  ];
}

// ==================================================
// Busca lista de templates
// ==================================================
function cleanSearchTemplates() {
  return {
    type: 'CLEAN_SEARCH_TEMPLATES_FETCHED',
    payload: '',
  };
}
function setSearchTemplates(items) {
  return {
    type: 'SEARCH_TEMPLATES_FETCHED',
    payload: items,
  };
}
function getSearchTemplate(query, _meta) {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().get(`/organizacoes/${organization}/localizar/templates?search=${query}&page=${_meta.current_page ? (_meta.current_page + 1) : 1}&per_page=${_meta.per_page || 50}`)
      .then((items) => {
        const _newMeta = {
          current_page: _meta.current_page || 0,
          per_page: _meta.per_page || 50,
          total_items: items.data.Meta.totalItems,
          total_pages: items.data.Meta.totalPage,
        };
        dispatch(setSearchTemplates({ resultado: items.data.data, _meta: _newMeta }));
      })
      .catch((error) => {
        dispatch(setSearchTemplates({ data: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },));
      })
      .finally(() => {
      });
  };
}
export function getSearchTemplates(query, _meta) {
  return [
    cleanSearchTemplates(),
    getSearchTemplate(query, _meta)
  ];
}

// ==================================================
// Busca lista de landing pages
// ==================================================
function cleanSearchLandingPages() {
  return {
    type: 'CLEAN_SEARCH_LANDING_PAGES_FETCHED',
    payload: '',
  };
}
function setSearchLandingPages(items) {
  return {
    type: 'SEARCH_LANDING_PAGES_FETCHED',
    payload: items,
  };
}
export function getSearchLandingPage(query, _meta) {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().get(`/organizacoes/${organization}/localizar/landing_pages?search=${query}&page=${_meta.current_page ? (_meta.current_page + 1) : 1}&per_page=${_meta.per_page || 50}`)
      .then((items) => {
        const _newMeta = {
          current_page: _meta.current_page || 0,
          per_page: _meta.per_page || 50,
          total_items: items.data.Meta.totalItems,
          total_pages: items.data.Meta.totalPage,
        };
        dispatch(setSearchLandingPages({ resultado: items.data.data, _meta: _newMeta }));
      })
      .catch((error) => {
        dispatch(setSearchLandingPages({ data: [], _meta: { current_page: 0, per_page: 50, total_items: 0, total_pages: 0 } },));
      })
      .finally(() => {
      });
  };
}
export function getSearchLandingPages(query, _meta) {
  return [
    cleanSearchLandingPages(),
    getSearchLandingPage(query, _meta)
  ];
}

// ==================================================
// Salve pesquisa
// ==================================================
function cleanSearchQueryList() {
  return {
    type: 'CLEAN_SEARCH_QUERY_LIST_FETCHED',
    payload: '',
  };
}
function setSearchQueryList(items) {
  return {
    type: 'SEARCH_QUERY_FETCHED',
    payload: items,
  };
}

// ==================================================
// Salve filtros
// ==================================================
function cleanSearchFilterList() {
  return {
    type: 'CLEAN_SEARCH_FILTER_LIST_FETCHED',
    payload: '',
  };
}
function setSearchFilterList(items) {
  return {
    type: 'SEARCH_FILTER_FETCHED',
    payload: items,
  };
}
