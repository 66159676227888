import Router from 'next/router'
import cookies from 'js-cookie'
import { removeCookies } from 'helpers/cookies'
import { get, post, publicPost, put } from 'helpers/fetch'
import { getOrganizations } from 'api/organizations'

export async function postLogin(payload) {
  const { remember } = payload
  const secure = process.env.NODE_ENV === 'production'
  const expires = remember ? 7 : null
  const response = await publicPost('/login', payload)

  if (response.ok) {
    const { per_page } = cookies.getJSON()
    const { accessExpiresAt, csrf, orgs } = await response.json()
    const organizations = await getOrganizations().then(response =>
      response.json()
    )

    if (!per_page) cookies.set('per_page', 10, { secure, expires: 365 })

    cookies.set('access_expires_at', accessExpiresAt, { secure, expires })
    cookies.set('csrf', csrf, { secure, expires })
    cookies.set('authenticated', true, { secure, expires })
    cookies.set('remember', remember, { secure, expires })

    if (orgs === 1) {
      const organization = organizations[0]

      cookies.set('organization', organization.id, { secure, expires })

      if (organization.aceitouTos) {
        Router.push('/home')
      } else {
        Router.push('/tos')
      }
    } else {
      Router.push('/organizations')
    }
  }

  return response
}

export async function postPasswordReset(payload) {
  const response = await publicPost('/password_reset', payload)

  return response
}

export async function postLogout() {
  await post('/logout', {})

  removeCookies()

  Router.push('/login')
}

export async function getIndex(ctx) {
  const response = await get('/', ctx)

  return response
}

export async function putAcceptTos(organization, payload) {
  const url = `/organizacoes/${organization}/aceito_termos`
  const response = await put(url, payload)

  return response
}
