import React, { useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import Router, { useRouter } from 'next/router';
import { compose } from 'recompose';
import { withTranslation } from 'i18n';
import { TextField, InputAdornment, Grid, ClickAwayListener } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';

// Componentes
import SearchList from 'src/components/search/SearchList';

// Actions
import { initSearch } from 'src/modules/search/SearchAction';

function SearchBox(props) {
  const dispatch = useDispatch();

  const { pathname } = useRouter();

  const [open, setOpen] = useState(false);
  const [filter, setFilter] = useState([ 'contacts' ]);
  const [query, setQuery] = useState('');

  if (!props.organization.enableSearch) {
    return (
      <Fragment />
    )
  }

  const onFocus = () => {
    if (!open) setOpen(true);
  }

  const onClose = () => {
    setOpen(false);
    setFilter([ 'contacts' ]);
    setQuery('');
  }

  const onCatchKey = (event) => {
    if (event.key === 'Enter' && !!query) {
      event.preventDefault();
      onSearch();
    }
  }

  const onSearch = () => {
    if (!!query) {
      const newQuery = query;
      const newFilter = filter;

      onClose();

      dispatch(initSearch(newQuery, newFilter));
      Router.push('/search')
    }
  }

  const onFilter = (value) => {
    let newList = [];

    if (value === '') {
      newList = [];
    } else if (filter.filter((e) => e === value).length > 0) {
      newList = filter.filter((e) => e !== value);
    } else if (filter.filter((e) => e === value).length === 0) {
      newList = filter.filter((e) => e !== '');
      newList.push(value);
    }

    setFilter(newList);
  }

  return (
    <ClickAwayListener onClickAway={onClose}>
      <BoxGrid>
        <TextFieldMT
          variant="outlined"
          size="small"
          color='primary'
          sx={{ borderRadius: 5 }}
          placeholder={props.t('Clique_aqui_para_procurar_')}
          inputProps={{
            id: 'search-input',
            onFocus: onFocus,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <IconEnd icon={faMagnifyingGlass} onClick={() => onSearch()} />
              </InputAdornment>
            ),
          }}
          onKeyPress={onCatchKey}
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          disabled={pathname === '/search'}
        />
        <SearchList
          open={open}
          filter={filter}
          onFilter={(e) => onFilter(e)}
        />
      </BoxGrid>
    </ClickAwayListener>
  );
}

export default compose(
  withTranslation('translate')
)(SearchBox);

// ==================================================
// Estilos
// ==================================================
const BoxGrid = styled(Grid)`
  position: relative;
  display: flex;
  flex-direction: column;
`;

const TextFieldMT = styled(TextField)`
  min-width: 250px;
  max-width: 250px;
  background-color: #FFFFFF;
  border-radius: 5px !important;
  .MuiOutlinedInput-notchedOutline {
    border: solid 2px #BDBDBD !important;
    border-radius: 5px !important;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0px !important;
  }
`;

const IconEnd = styled(FontAwesomeIcon)`
  font-size: 18px;
  color: #BDBDBD;
  cursor: pointer;
`;
