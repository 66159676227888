import Router from 'next/router'
import fetch from 'isomorphic-unfetch'
import cookies from 'js-cookie'
import { pick } from 'lodash'
import { removeCookies } from 'helpers/cookies'

let headers = {
  'Content-Type': 'application/json',
  'Key-Inflection': 'camel',
}

export async function get(url, ctx = {}) {
  const { req } = ctx

  return await fetch(`${process.env.MAILTOP_API}${url}`, {
    method: 'get',
    headers: req
      ? Object.assign({ cookie: req.headers.cookie }, headers)
      : headers,
    credentials: 'include',
  })
    .then((response) => checkResponse(response, ctx))
    .then((response) => response)
}

export async function post(url, payload) {
  headers['Content-Type'] = 'application/json'
  const { csrf } = cookies.getJSON()

  return await fetch(`${process.env.MAILTOP_API}${url}`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers: Object.assign({ 'X-CSRF-Token': csrf }, headers),
    credentials: 'include',
  })
    .then((response) => checkResponse(response))
    .then((response) => response)
}

export async function publicPost(url, payload) {
  return await fetch(`${process.env.MAILTOP_API}${url}`, {
    method: 'post',
    body: JSON.stringify(payload),
    headers,
    credentials: 'include',
  }).then((response) => response)
}

export async function postFile(url, payload, isPublic = false) {
  headers = pick(headers, ['Key-Inflection'])
  const { csrf } = cookies.getJSON()

  return await fetch(`${process.env.MAILTOP_API}${url}`, {
    method: 'post',
    body: payload,
    headers: isPublic
      ? headers
      : Object.assign({ 'X-CSRF-Token': csrf }, headers),
    credentials: 'include',
  })
    .then((response) => checkResponse(response))
    .then((response) => response)
}

export async function put(url, payload) {
  const { csrf } = cookies.getJSON()

  return await fetch(`${process.env.MAILTOP_API}${url}`, {
    method: 'put',
    body: JSON.stringify(payload),
    headers: Object.assign({ 'X-CSRF-Token': csrf }, headers),
    credentials: 'include',
  })
    .then((response) => checkResponse(response))
    .then((response) => response)
}

export async function del(url) {
  const { csrf } = cookies.getJSON()

  return await fetch(`${process.env.MAILTOP_API}${url}`, {
    method: 'delete',
    headers: Object.assign({ 'X-CSRF-Token': csrf }, headers),
    credentials: 'include',
  })
    .then((response) => checkResponse(response))
    .then((response) => response)
}

function checkResponse(response, ctx = {}) {
  const { req, res } = ctx

  if (response.ok) {
    return response
  } else {
    const { url, status } = response

    if (status === 401 && !url.endsWith('/logout')) {
      removeCookies(req, res)

      if (!!req) {
        res.cookie('timed_out', true)
        res.writeHead(302, { Location: '/login' })
        res.end()
      } else {
        cookies.set('timed_out', true)
        Router.replace('/login')
      }

      return Promise.reject()
    }

    return response
  }
}
