import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles(theme => ({
  wrapper: {
    overflowX: 'auto',
  },
  customScrollbar: {
    '& > .ps__rail-y': {
      opacity: 0.6,
    },
  },
}))

const TableWrapper = ({
  children,
  scrollbarAlwaysVisible = false,
  wrapperProps = {},
}) => {
  const classes = useStyles()

  return (
    <Grid className={clsx(classes.wrapper, wrapperProps.classes)}>
      <PerfectScrollbar
        className={clsx({ [classes.customScrollbar]: scrollbarAlwaysVisible })}
      >
        {children}
      </PerfectScrollbar>
    </Grid>
  )
}

export default TableWrapper
