import axios from 'axios';
import rateLimit from 'axios-rate-limit';
import cookies from 'js-cookie';
import Router from 'next/router';

export const axiosInstance = () => rateLimit(axios.create({ baseURL: process.env.MAILTOP_API, withCredentials: true }), { maxRequests: 10, perMilliseconds: 1000, maxRPS: 10 });
export const instance = axiosInstance();

export const axiosInstanceWithAuth = (inst) => {
  const cookie = cookies.getJSON();

  let language = 'pt';
  if (cookie['next-i18next'] === 'es' || cookie['next-i18next'] === 'es-es') language = 'es';
  if (cookie['next-i18next'] === 'en' || cookie['next-i18next'] === 'en-us') language = 'en';

  const { defaults } = inst;
  defaults.credentials = 'include';
  defaults.headers['Content-Type'] = 'application/json';
  defaults.headers['Key-Inflection'] = 'camel';
  defaults.headers['x-csrf-token'] = cookie.csrf;
  defaults.headers['Accept-Language'] = language;


  return { ...inst, defaults };
};

instance.interceptors.response.use(response => response,
  async (error) => {
    const { response: { status } } = error;

    if (status === 401) {
      console.error('Clear storage');
      cookies.set('timed_out', true);
      Router.replace('/login');
    }

    return Promise.reject(error);
  });

export default () => axiosInstanceWithAuth(instance);
