import React, { useState } from 'react'
import Link from '@material-ui/core/Link'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.white,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const RegularLinks = ({ links }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const { title, items } = links

  function toggleMenu(event) {
    event.preventDefault()

    const { currentTarget } = event

    if (!anchorEl) {
      setAnchorEl(currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  return (
    <>
      <Typography onClick={toggleMenu} className={classes.text}>
        {title} &nbsp; | &nbsp;
      </Typography>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={_ => setAnchorEl(null)}
      >
        <List>
          {items.map((item, index) => {
            const { label, url } = item
            return (
              <ListItem
                key={index}
                button
                component={Link}
                href={url}
                underline='none'
                target='_blank'
                rel='noopener noreferrer'
              >
                <ListItemText
                  primary={label}
                  primaryTypographyProps={{
                    variant: 'body2',
                    color: 'textPrimary',
                  }}
                />
              </ListItem>
            )
          })}
        </List>
      </Popover>
    </>
  )
}

export default RegularLinks
