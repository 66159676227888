// import cookies from 'js-cookie';
// import { toastr } from 'react-redux-toastr';

// // Serviços
// import Api from 'src/services/Api';

// // Actions
// import { statusLoader } from 'src/components/loader/LoaderAction';
// import { initList } from 'src/modules/list/listList/ListListAction';
// import { closeModalListDelete } from 'src/components/modal/modalListDelete/ModalListDeleteAction';

// ==================================================
// Seleciona aba do menu
// ==================================================
export function setPage(value) {
  return {
    type: 'BILLING_MODULE_STEP_FETCHED',
    payload: value,
  };
}

// // ==================================================
// // Excluir list
// // ==================================================
// function sendDelete(list) {
//   const { organization } = cookies.getJSON();

//   return (dispatch) => {
//     Api().delete(`/organizacoes/${organization}/listas/${list.id || 0}`)
//       .then((items) => {
//         toastr.success('', 'Lista excluída com sucesso');
//         dispatch(initList(organization, ''));
//         dispatch(closeModalListDelete());
//       })
//       .catch((error) => {
//         toastr.error('', 'Erro ao excluir lista');
//       })
//       .finally(() => {
//         dispatch(statusLoader(false));
//       });
//   };
// }
// export function sendDeleteList(list) {
//   return [
//     statusLoader(true),
//     sendDelete(list),
//   ]
// }
