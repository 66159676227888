import React, { Component } from 'react'
import Router from 'next/router'
import cookies from 'js-cookie'
import { isEmpty } from 'lodash'
import md5 from 'md5'
import moment from 'moment'
import { getCookies, removeCookies } from 'helpers/cookies'
import { getIndex } from 'api/auth'
import { getCredits } from 'api/credits'
import { getOrganizations } from 'api/organizations'
import { getUserPermission } from 'api/users'
import UserContext from 'components/UserContext'

const withAuthentication = (roles) => (WrappedComponent) =>
  class extends Component {
    static async getInitialProps(ctx) {
      const { req, res, pathname } = ctx
      const {
        authenticated,
        access_expires_at,
        organization: selected_org,
      } = getCookies(ctx)
      const expirationTime = moment(access_expires_at)
      const now = moment(new Date())

      if (authenticated) {
        /**
         * console.info('\n\n\n')
         * console.info('withAuthentication - authenticated = true:')
         * console.info('\n')
         * console.info('req is undefined?', typeof req === 'undefined')
         * if (typeof req !== 'undefined') {
         *   console.info('url:', req.url)
         *   console.info('headers:', req.headers)
         *   console.info('cookies:', req.cookies)
         * }
         * console.info('\n')
         * console.info('res is undefined?', typeof res === 'undefined')
         * if (typeof res !== 'undefined') {
         *   console.info('statusCode:', res.statusCode)
         * }
         * console.info('\n')
         */

        if (now.isAfter(expirationTime)) {
          /**
           * console.info('access expired:')
           * console.info('current time:', now.format())
           * console.info('expiration time:', expirationTime.format())
           * console.info('\n')
           */
          removeCookies(req, res)

          if (res) {
            res.cookie('timed_out', true)
            res.writeHead(302, { Location: '/login' })
            res.end()

            return {}
          }

          cookies.set('timed_out', true)
          Router.push('/login')

          return {}
        }

        const [user, organizations] = await Promise.all([
          getIndex(ctx).then((response) => response.json()),
          getOrganizations(ctx).then((response) => response.json()),
        ])

        const gravatar =
          'https://www.gravatar.com/avatar/$md5.jpg?s=30&d=identicon'
        user.avatar = gravatar.replace('$md5', md5(user.email))
        let organization =
          organizations && selected_org
            ? organizations.find(
                (organization) => organization.id === Number(selected_org)
              )
            : {}

        let permission = {}
        let credits = {}
        if (!isEmpty(organization)) {
          const [resPermission, resCredits] = await Promise.all([
            getUserPermission(organization.id, ctx).then((response) =>
              response.text()
            ),
            getCredits(organization.id, ctx).then((response) =>
              response.json()
            ),
          ])

          permission = resPermission
          credits = resCredits
        }

        user.permission = permission

        if (
          !isEmpty(organization) &&
          !organization.aceitouTos &&
          pathname !== '/tos'
        ) {
          if (res) {
            res.writeHead(302, { Location: '/tos' })
            res.end()

            return {}
          }

          Router.push('/tos')
        }
        if (
          permission !== 'administração' &&
          roles &&
          !roles.includes(permission)
        ) {
          if (res) {
            res.writeHead(302, { Location: '/404' })
            res.end()

            return {}
          }

          Router.push('/404')
        }

        if (!isEmpty(organization) && pathname === '/home') {
          const showChecklist = !organization.listaCriada

          if (showChecklist) {
            if (res) {
              res.writeHead(302, { Location: '/checklist' })
              res.end()

              return {}
            }

            Router.push('/checklist')
          }
        }

        if (isEmpty(organization) && pathname !== '/organizations') {
          if (res) {
            res.writeHead(302, { Location: '/organizations' })
            res.end()

            return {}
          }

          Router.push('/organizations')

          return {}
        }

        const componentProps = WrappedComponent.getInitialProps
          ? await WrappedComponent.getInitialProps(ctx)
          : {}
        const userProps = {
          organizations,
          organization,
          user,
          ...componentProps,
        }

        if (!isEmpty(organization)) {
          return { credits, ...userProps }
        }

        /* console.info('\n\n\n') */
        return userProps
      } else {
        /**
         * console.info('\n\n\n')
         * console.info('withAuthentication: - authenticated = false')
         */

        if (res) {
          res.writeHead(302, { Location: '/login' })
          res.end()

          return {}
        }

        Router.push('/login')

        /* console.info('\n\n\n') */
        return {}
      }
    }

    render() {
      const { organizations, organization, user, credits } = this.props

      return (
        <UserContext.Provider
          value={{ organizations, organization, user, credits }}
        >
          <WrappedComponent roles={roles} {...this.props} />
        </UserContext.Provider>
      )
    }
  }

export default withAuthentication
