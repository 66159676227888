import React, { Fragment } from 'react';
import { compose } from 'recompose';
import { withTranslation } from 'i18n';
import { Paper, List } from '@material-ui/core';
import styled from 'styled-components';

// Componentes
import SearchItem from 'src/components/search/SearchItem';

function SearchList(props) {
  if (!props.open) {
    return (<Fragment />)
  }

  return (
    <BoxPaper>
      <List>
        <SearchItem filter={props.filter} value='' label={props.t('Em_tudo')} onFilter={(e) => props.onFilter(e)} />
        <SearchItem filter={props.filter} value='contacts' label={props.t('Contatos')} onFilter={(e) => props.onFilter(e)} />
        <SearchItem filter={props.filter} value='lists' label={props.t('Listas')} onFilter={(e) => props.onFilter(e)} />
        <SearchItem filter={props.filter} value='campaigns' label={props.t('Campanhas')} onFilter={(e) => props.onFilter(e)} />
        <SearchItem filter={props.filter} value='templates' label={props.t('Templates')} onFilter={(e) => props.onFilter(e)} />
        <SearchItem filter={props.filter} value='landing_pages' label={props.t('Landing_Pages')} onFilter={(e) => props.onFilter(e)} />
      </List>
    </BoxPaper>
  );
}

export default compose(
  withTranslation('translate')
)(SearchList);

// ==================================================
// Estilos
// ==================================================
const BoxPaper = styled(Paper)`
  position: absolute;
  top: 40px;
  width: 250px;
`;
