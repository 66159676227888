import React from 'react'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/styles'
import FacebookIcon from '@material-ui/icons/Facebook'
import InstagramIcon from '@material-ui/icons/Instagram'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import clsx from 'clsx'
import moment from 'moment'
import { withTranslation } from 'i18n'
import RegularLinks from 'layout/Footer/RegularLinks'
import SocialLinks from 'layout/Footer/SocialLinks'
import LanguageSelector from 'layout/Footer/LanguageSelector'

const useStyles = makeStyles(theme => ({
  flex: props => ({
    display: 'flex',
    justifyContent: props.isDownSm ? 'center' : 'space-between',
    alignItems: 'center',
    alignContent: 'space-around',
    flexWrap: 'wrap',
  }),
  container: {
    backgroundColor: theme.palette.grey[800],
    padding: theme.spacing(1, 4),
  },
  menuContainer: props => ({
    margin: props.isDownSm ? theme.spacing(1, 4) : theme.spacing(0, 4),
  }),
  text: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.common.white,
  },
}))

const Footer = ({ t, className }) => {
  const theme = useTheme()
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = useStyles({ isDownSm })
  const resources = {
    title: t('title.resources'),
    items: [
      {
        label: t('links.resources.serviceStatus'),
        url: 'https://status.mailtop.com.br',
      },
      {
        label: t('links.resources.docs'),
        url: 'https://docs.mailtop.com.br',
      },
      {
        label: t('links.resources.faq'),
        url: 'https://docs.mailtop.com.br/faq',
      },
      {
        label: t('links.resources.blog'),
        url: 'https://blog.mailtop.com.br/',
      },
      {
        label: t('links.resources.ipAddresses'),
        url: 'https://info.mailtop.com.br/enderecos-ip',
      },
      {
        label: t('links.resources.labs'),
        url: 'https://info.mailtop.com.br/labs',
      },
    ],
  }
  const legal = {
    title: t('title.legal'),
    items: [
      {
        label: t('links.legal.terms'),
        url: 'https://info.mailtop.com.br/termos-de-uso',
      },
      {
        label: t('links.legal.privacy'),
        url: 'https://info.mailtop.com.br/privacidade',
      },
      {
        label: t('links.legal.abuse'),
        url: 'https://info.mailtop.com.br/abuso',
      },
      {
        label: t('links.legal.cookies'),
        url: 'https://info.mailtop.com.br/cookies',
      },
      {
        label: t('links.legal.use'),
        url: 'https://info.mailtop.com.br/uso-aceitavel',
      },
      {
        label: t('links.legal.api'),
        url: 'https://info.mailtop.com.br/uso-api',
      },
      {
        label: t('links.legal.trademark'),
        url: 'https://info.mailtop.com.br/marca',
      },
      {
        label: t('links.legal.copyright'),
        url: 'https://info.mailtop.com.br/direitos-autorais',
      },
    ],
  }
  const social = {
    title: t('title.followUs'),
    items: [
      {
        icon: FacebookIcon,
        label: 'Facebook',
        url: 'https://business.facebook.com/MailtopBR',
      },
      {
        icon: InstagramIcon,
        label: 'Instagram',
        url: 'https://instagram.com/MailtopBR',
      },
      {
        icon: LinkedInIcon,
        label: 'LinkedIn',
        url: 'https://www.linkedin.com/company/mailtopbr',
      },
    ],
  }

  return (
    <Grid
      component='footer'
      className={clsx(classes.container, classes.flex, className)}
    >
      <Grid className={classes.flex}>
        <LanguageSelector />
        <Grid className={clsx(classes.menuContainer, classes.flex)}>
          <RegularLinks links={resources} />
          <RegularLinks links={legal} />
          <SocialLinks links={social} />
        </Grid>
      </Grid>
      <Typography className={classes.text}>
        {`Copyright © 2016-${moment().format('YYYY')} Syonet Informática Ltda.`}
      </Typography>
    </Grid>
  )
}

export default withTranslation('footer')(Footer)
