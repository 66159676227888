/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Router from 'next/router';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import { Avatar, AppBar, Button, Grid, Hidden, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import { includes, orderBy, words } from 'lodash'
import { withTranslation } from 'i18n'
import Link from 'components/Link'
import UserContext from 'components/UserContext'

// Componentes
import AutomationPlan from 'src/components/layout/messages/automationPlan/AutomationPlan';
import SearchBox from 'src/components/search/SearchBox';

// Actions
import { setPage } from 'src/modules/billing/BillingAction';

const Drawer = dynamic(() => import('layout/Header/Drawer'));
const Dropdown = dynamic(() => import('layout/Header/Dropdown'));

const useStyles = makeStyles((theme) => ({
  flex: {
    display: 'inline-flex',
    flex: 'auto',
    alignItems: 'center',
  },
  right: {
    textAlign: 'right',
  },
  appBar: (props) => ({
    top: props.warning ? theme.spacing(5) : 0,
    padding: theme.spacing(0, 4),
    backgroundColor: theme.palette.primary.light,
  }),
  testAppBar: {
    padding: theme.spacing(0, 2),
    backgroundColor: theme.palette.secondary.light,
  },
  toolbar: {
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  testToolbar: {
    minHeight: theme.spacing(5),
    justifyContent: 'space-around',
  },
  testMessageContainer: {
    justifyContent: 'center',
  },
  hireNow: {
    marginLeft: theme.spacing(1),
  },
  menuAndBrand: {
    justifyContent: 'space-between',
    '@media(min-width:1024px)': {
      flex: 0,
    },
  },
  hideMin: {
    '@media(min-width:1024px)': {
      display: 'none',
    },
  },
  nav: {
    width: '100%',
    '@media(max-width:1024px)': {
      display: 'none',
    },
  },
  searchContainer: {
    justifyContent: 'space-between',
    paddingRight: theme.spacing(2),
  },
  link: {
    height: theme.spacing(4),
  },
  image: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  list: {
    display: 'flex',
    flexDirection: 'row',
  },
  listItem: {
    height: theme.spacing(4),
    margin: `auto ${theme.spacing(1) / 2}px`,
    textAlign: 'center',
  },
  listItemLp: {
    height: theme.spacing(4),
    margin: `auto ${theme.spacing(1) / 2}px`,
    textAlign: 'center',
  },
  listItemText: {
    fontSize: 12,
    whiteSpace: 'nowrap',
  },
  pages: {
    paddingLeft: theme.spacing(2),
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdown: {
    justifyContent: 'flex-end',
  },
  userInfo: {
    width: 152,
    marginRight: theme.spacing(2),
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

const Header = ({ t, pathname }) => {
  const dispatch = useDispatch();

  const automationPlan = useSelector((state) => state.automationPlanReducer.automationPlan);

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDraweOpen] = useState(false);

  const { organizations, organization, user, credits } = useContext(UserContext)
  const { nome, id, periodoTeste, faturasAtrasadas } = organization
  const orderedOrganizations = orderBy(organizations, (organization) => organization.nome)
  const { nome: name, email, orgs, avatar } = user
  const classes = useStyles({ warning: periodoTeste || faturasAtrasadas || automationPlan.automationExceedingPlan })
  const { saldo } = credits

  const pages = [
    { label: t('lists'), href: '/lists', visibled: true },
    { label: t('templates'), href: '/templates', visibled: true },
    { label: t('Email_marketing'), href: '/campaign', visibled: true },
    { label: t('automation'), href: '/automation', visibled: organization.automationActivated },
    { label: t('landing_page'), href: '/landing-pages', visibled: organization.automationActivated },
  ]

  const open = Boolean(anchorEl)

  function toggleMenu(event) {
    event.preventDefault()

    const { currentTarget } = event

    if (!anchorEl) {
      setAnchorEl(currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  function clickDropdown() {
    const button = document.getElementById('dropdown')
    button.click()
  }

  const onBillingPlan = () => {
    Router.push(`/billing`)
  }

  const onBillingHistory = () => {
    dispatch(setPage('history_invoice_bar'))
    Router.push(`/billing`)
  }

  return (
    <>
      {/* Barra de informação de upgrade do plano */}
      <AutomationPlan />

      {(periodoTeste || faturasAtrasadas) && (
        <AppBar className={classes.testAppBar}>
          <Toolbar className={clsx(classes.toolbar, classes.testToolbar)}>
            <Grid className={clsx(classes.flex, classes.testMessageContainer)}>
              <Typography>
                {periodoTeste && (periodoTeste === -1 ? t('testPeriod.expired') : t('testPeriod.timeLeft', { count: periodoTeste }))}
                {faturasAtrasadas && t('lateInvoices.message')}
              </Typography>

              {periodoTeste && (
                <Button size='small' className={classes.hireNow} onClick={() => onBillingPlan()}>
                  {t('testPeriod.hireNow')}
                </Button>
              )}

              {faturasAtrasadas && (
                <Button size='small' className={classes.hireNow} onClick={() => onBillingHistory()}>
                  {t('lateInvoices.button')}
                </Button>
              )}
            </Grid>
          </Toolbar>
        </AppBar>
      )}

      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <Grid className={clsx(classes.flex, classes.menuAndBrand)}>
            <Grid className={classes.hideMin}>
              <IconButton color='inherit' onClick={() => setDraweOpen(!drawerOpen)}>
                <MenuIcon />
              </IconButton>
            </Grid>
            <NextLink href='/'>
              <a className={classes.link}>
                <img src='/static/images/mailtop/logo-ba.svg' alt='' className={classes.image} />
              </a>
            </NextLink>
          </Grid>

          <Grid className={clsx(classes.flex, classes.nav)}>
            <Grid className={clsx(classes.flex, classes.pages)}>
              <List className={classes.list}>
                {pages.map((page, index) => {
                  if (!page.visibled) {
                    return (<Fragment />)
                  }

                  return (
                    <Fragment key={index}>
                      {page && (
                        <>
                          <ListItem
                            button
                            component={Link}
                            href={page.href}
                            selected={includes(
                              words(page.href),
                              words(pathname)[0]
                            )}
                            className={classes.listItem}
                          >
                            <ListItemText primary={page.label} primaryTypographyProps={{ variant: 'button', className: classes.listItemText }} />
                          </ListItem>
                        </>
                      )}
                    </Fragment>
                  )
                })}
              </List>

            </Grid>
            <Grid className={clsx(classes.flex, classes.searchContainer)}>
              <SearchBox organization={organization} />
            </Grid>
          </Grid>

          <Grid className={clsx(classes.flex, classes.dropdown)}>
            <Hidden implementation='css' smDown>
              <Grid className={clsx(classes.right, classes.userInfo)} onClick={() => clickDropdown()}>
                <Typography variant='subtitle2' noWrap>
                  {name}
                </Typography>
                <Typography variant='caption' noWrap>
                  {orgs > 1 ? nome : email}
                </Typography>
              </Grid>
            </Hidden>

            <Avatar className={classes.avatar} src={avatar} onClick={() => clickDropdown()} />
            <IconButton
              id='dropdown'
              aria-owns={open ? 'dropdown' : undefined}
              aria-haspopup={true}
              color='inherit'
              onClick={toggleMenu}
            >
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </IconButton>
          </Grid>
        </Toolbar>
      </AppBar>
      <Dropdown
        anchorEl={anchorEl}
        open={open}
        onClose={toggleMenu}
        info={{ name, orgs, nome, email, organizationId: id, saldo }}
        organizations={orderedOrganizations}
        t={t}
      />
      <Drawer
        open={drawerOpen}
        onClose={() => setDraweOpen(!drawerOpen)}
        pages={pages}
      />
    </>
  )
}

export default withTranslation(['header', 'translate'])(Header)
