import cookies from 'js-cookie'
import nextCookies from 'next-cookies'

export function getCookies(ctx) {
  if (ctx.req) return nextCookies(ctx)

  return cookies.getJSON()
}

export function removeCookies(req, res) {
  removePageCookies(req, res)

  if (!!res) {
    res.clearCookie('access_expires_at')
    res.clearCookie('csrf')
    res.clearCookie('authenticated')
    res.clearCookie('remember')
    res.clearCookie('organization')
  } else {
    cookies.remove('access_expires_at')
    cookies.remove('csrf')
    cookies.remove('authenticated')
    cookies.remove('remember')
    cookies.remove('organization')
  }
}

export function removePageCookies(req, res) {
  const jsonCookies = !!req ? req.cookies : cookies.getJSON()

  /* eslint-disable no-unused-vars */
  for (const key in jsonCookies) {
    if (key !== 'per_page' && /(\w+_page)/gi.test(key)) {
      if (key === 'contacts_page') {
        if (!!res) {
          res.clearCookie('contacts_order')
          res.clearCookie('contacts_unsubscribed')
          res.clearCookie('contacts_list')
        } else {
          cookies.remove('contacts_order')
          cookies.remove('contacts_unsubscribed')
          cookies.remove('contacts_list')
        }
      }

      if (key === 'lists_page') {
        if (!!res) {
          res.clearCookie('contacts_list')
        } else {
          cookies.remove('contacts_list')
        }
      }

      if (key === 'templates_page') {
        if (!!res) {
          res.clearCookie('templates_tag')
          res.clearCookie('duplicate_template')
        } else {
          cookies.remove('templates_tag')
          cookies.remove('duplicate_template')
        }
      }

      if (key === 'campaigns_page') {
        if (!!res) {
          res.clearCookie('campaigns_state')
          res.clearCookie('campaigns_tag')
          res.clearCookie('duplicate_campaign')
        } else {
          cookies.remove('campaigns_state')
          cookies.remove('campaigns_tag')
          cookies.remove('duplicate_campaign')
        }
      }

      if (key === 'automation_page') {
        if (!!res) {
          res.clearCookie('automation_state')
        } else {
          cookies.remove('automation_state')
        }
      }

      !!res ? res.clearCookie(key) : cookies.remove(key)
    }
  }
}
