import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/styles'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import moment from 'moment'
import { i18n, withTranslation } from 'i18n'

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.common.white,
  },
  listItemText: {
    fontSize: 12,
  },
}))

const LanguageSelector = ({ t }) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const classes = useStyles()
  const open = Boolean(anchorEl)

  function toggleMenu(event) {
    event.preventDefault()

    const { currentTarget } = event

    if (!anchorEl) {
      setAnchorEl(currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  function changeLanguage(language) {
    i18n.changeLanguage(language)

    language === 'pt' ? moment.locale('pt-br') : moment.locale(language)

    setAnchorEl(null)
  }

  return (
    <>
      <Button
        variant='contained'
        size='large'
        endIcon={open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        onClick={toggleMenu}
        className={classes.button}
      >
        {t('components.languageSelector.language')}
      </Button>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        open={open}
        onClose={_ => setAnchorEl(null)}
      >
        <List>
          <ListItem button onClick={_ => changeLanguage('en')}>
            <ListItemText
              inset
              primary={t('components.languageSelector.english')}
              primaryTypographyProps={{
                variant: 'button',
                className: classes.listItemText,
              }}
            />
          </ListItem>
          <ListItem button onClick={_ => changeLanguage('es')}>
            <ListItemText
              inset
              primary={t('components.languageSelector.spanish')}
              primaryTypographyProps={{
                variant: 'button',
                className: classes.listItemText,
              }}
            />
          </ListItem>
          <ListItem button onClick={_ => changeLanguage('pt')}>
            <ListItemText
              inset
              primary={t('components.languageSelector.brazilianPortuguese')}
              primaryTypographyProps={{
                variant: 'button',
                className: classes.listItemText,
              }}
            />
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default withTranslation('common')(LanguageSelector)
