import cookies from 'js-cookie';

// Serviços
import Api from 'src/services/Api';

// Actions
import { statusLoader } from 'src/components/loader/LoaderAction';
import { initAutomationPlan } from 'src/components/layout/messages/automationPlan/AutomationPlanAction';

// ==================================================
// Fechar modal
// ==================================================
function closeModal() {
  return {
    type: 'CLOSE_MODAL_AUTOMATION_PLAN_FETCHED',
    payload: false,
  };
}
function cleanList() {
  return {
    type: 'CLEAN_MODAL_AUTOMATION_PLAN_LIST_FETCHED',
    payload: '',
  };
}
export function closeModalAutomationPlan() {
  return [
    cleanList(),
    closeModal(),
  ];
}

// ==================================================
// Abrir modal
// ==================================================
function openModal() {
  return {
    type: 'OPEN_MODAL_AUTOMATION_PLAN_FETCHED',
    payload: true,
  };
}
function setList(automation) {
  return {
    type: 'MODAL_AUTOMATION_PLAN_LIST_FETCHED',
    payload: automation,
  };
}
export function openModalAutomationPlan(automation) {
  return [
    setList(automation),
    openModal(),
  ];
}

// ==================================================
// Envial OK
// ==================================================
function sendUpdate() {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().post(`/organizacoes/${organization}/confirm_notification`, { confirmed: 'AUTOMATION_PLAN_UPGRADED' })
      .then((items) => {
        dispatch(initAutomationPlan());
        dispatch(closeModalAutomationPlan());
      })
      .catch(() => {
      })
      .finally(() => {
        dispatch(statusLoader(false));
      });
  };
}
export function sendAutomationplan() {
  return [
    statusLoader(true),
    sendUpdate(),
  ];
}

