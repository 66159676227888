import React from 'react'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import PerfectScrollbar from 'react-perfect-scrollbar'

const useStyles = makeStyles((theme) => ({
  ul: (props) => ({
    marginTop: theme.spacing(4),
    height: props.height,
  }),
  li: {
    margin: theme.spacing(1, 0),
    color: theme.palette.text.secondary,
  },
  customScrollbar: {
    '& > .ps__rail-y': {
      opacity: 0.6,
    },
  },
}))

const Bullet = ({ children, ...props }) => {
  const classes = useStyles()

  return (
    <li className={classes.li} {...props}>
      {children}
    </li>
  )
}

export { Bullet }

const BulletList = ({
  children,
  height = 'auto',
  scrollbarAlwaysVisible = false,
  ulProps = {},
  ...props
}) => {
  const classes = useStyles({ height })

  return (
    <PerfectScrollbar
      className={clsx({ [classes.customScrollbar]: scrollbarAlwaysVisible })}
    >
      <ul
        className={clsx(classes.ul, ulProps.className && ulProps.className)}
        {...props}
      >
        {children}
      </ul>
    </PerfectScrollbar>
  )
}

export default BulletList
