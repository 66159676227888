import { del, get, put } from 'helpers/fetch'

export async function getUsers(ctx) {
  const response = await get('/users', ctx)

  return response
}

export async function getUserPermission(organization, ctx) {
  const url = `/organizacoes/${organization}/usuario/permissao`
  const response = await get(url, ctx)

  return response
}

export async function getUserPublic(user, ctx) {
  const params = `?usuario=${user}`
  const response = await get(`/usuario_publico${params}`)

  return response
}

export async function putUser(payload) {
  const response = await put('/users', payload)

  return response
}

export async function putPassword(payload) {
  const response = await put('/users/password', payload)

  return response
}

export async function deleteUser(organization, user, ctx) {
  const response = await del(`/organizacoes/${organization}/usuario/${user}`)

  return response
}
