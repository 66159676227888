import cookies from 'js-cookie';

// Serviços
import Api from 'src/services/Api';

// ==================================================
// Verifica plano
// ==================================================
function setAutomationplan(automation) {
  return {
    type: 'AUTOMATION_PLAN_FETCHED',
    payload: automation,
  };
}
function getAutomationPlanOrganization(data) {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().get(`/organizations/${organization}`)
      .then((items) => {
        const newData = {...data};
        newData.organization = items.data;
        dispatch(setAutomationplan(newData));
      })
      .catch(() => {
        const newData = {...data};
        dispatch(setAutomationplan(newData));
      })
      .finally(() => {
      });
  };
}
function getAutomationPlan() {
  const { organization } = cookies.getJSON();

  return (dispatch) => {
    Api().get(`/organizacoes/${organization}/consult_notification`)
      .then((items) => {
        dispatch(getAutomationPlanOrganization(items.data.cache));
      })
      .catch(() => {
        dispatch(getAutomationPlanOrganization({
          automationPlanUpgraded: { next_plan: '' },
          automationExceedingPlan: false,
          organization: { nome: '', id: organization },
        }));
      })
      .finally(() => {
      });
  };
}
export function initAutomationPlan() {
  return [
    getAutomationPlan(),
  ];
}
