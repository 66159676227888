import React, { useContext } from 'react'
import Fade from '@material-ui/core/Fade'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/styles'
import Footer from 'layout/Footer'
import Header from 'layout/Header'
import AppContext from 'components/AppContext'
import UserContext from 'components/UserContext'

const useStyles = makeStyles(theme => ({
  container: {
    position: 'relative',
  },
  main: props => ({
    minHeight: '100vh',
    marginTop: props.warning ? 88 : theme.spacing(6),
    padding: theme.spacing(2, 4, 4),
    filter: props.searching ? 'blur(2px)' : 'none',
  }),
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
}))

const FullLayout = ({ children }) => {
  const { pathname, searching } = useContext(AppContext)
  const { organization } = useContext(UserContext)
  const { periodoTeste, faturasAtrasadas } = organization
  const classes = useStyles({
    searching,
    warning: periodoTeste || faturasAtrasadas,
  })

  return (
    <>
      <Header pathname={pathname} />
      <Grid className={classes.container}>
        <Grid component='main' className={classes.main}>
          {children}
        </Grid>
        <Fade in={searching}>
          <Grid className={classes.overlay} />
        </Fade>
      </Grid>
      <Footer />
    </>
  )
}

export default FullLayout
