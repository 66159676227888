import Router from 'next/router'
import { get, post, put } from 'helpers/fetch'

export async function postDownloadData(organization) {
  const url = `/organizacoes/${organization}/baixar_informacoes`
  const response = await post(url, {})

  return response
}

export async function getOrganizations(ctx) {
  const response = await get('/organizations', ctx)

  return response
}

export async function getOrganization(organization, ctx) {
  const response = await get(`/organizations/${organization}`, ctx)

  return response
}

export async function getContactFields(organization, ctx) {
  const url = `/organizacoes/${organization}/campos_contato`
  const response = await get(url, ctx)

  return response
}

export async function putOrganization(organization, payload) {
  const response = await put(`/organizations/${organization}`, payload)

  return response
}

export async function putChecklist(organization, item = -1) {
  let payload = {}

  switch (item) {
    case 0:
      payload = { dominioAdicionado: true }
      break
    case 1:
      payload = { listaCriada: true }
      break
    default:
      payload = { dominioAdicionado: true, listaCriada: true }
      break
  }

  const response = await put(`/organizations/${organization}`, payload)

  if (response.ok) Router.push('/home')
}
